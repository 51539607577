.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    top: 0;
    transform: translateY(-100%);
    height: 20px;
    width: 100%;
    position: absolute;
    
    @media(max-width:576px){
        height: 100px;
    }

    .prev-button {
        width: 90px;
        font-family: 'Times New Roman', Times, serif;
        cursor: pointer;
        background-color: transparent;
        color: rgb(148, 147, 147);
        font-size: 15px;
        border: transparent;
        text-transform: uppercase;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 130px;
        position: absolute;
        top: 0;
        height: 100vh;

        @media screen and (max-width:768px){
            margin-left: 30px;
            //height: 50vh;
        }
        @media screen and (max-width:1428px){
            align-items: end;
        }
    }

    .prev-button::after {
        content: '';
        position: absolute;
        width: calc(50vw - 545px);
        height: 0.1px;
        background-color: rgb(148, 147, 147);
        right: calc(-50vw + 545px);
        margin-left: 10px;  
        @media screen and (max-width:1428px){
            bottom: 12px;
        }
    }

    .prev-button:hover{
        color: white;

    }
    .next-button {
        width: 90px;
        font-family: 'Times New Roman', Times, serif;
        text-transform: uppercase;
        cursor: pointer;
        background-color: transparent;
        color: rgb(148, 147, 147);
        border: transparent;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        margin-right: 130px;
        top: 0;
        right: 0;
        height: 100vh;
        position: absolute;
        @media screen and (max-width:768px){
            margin-right: 30px;
        }
        @media screen and (max-width:1428px){
            align-items: end;
        }
    }

    .next-button::before {
        content: '';
        position: absolute;
        width: calc(50vw - 545px);
        height: 0.1px;
        left: calc(-50vw + 545px);
        background-color: rgb(148, 147, 147);
        @media screen and (max-width:1428px){
            bottom: 12px;
        }
    }
    .next-button:hover{
        color: white;

    }
}