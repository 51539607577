body {
  margin: 0;
  padding: 0;
}
.home-wrapper {
  font-family: 'Times New Roman', Times, serif;
  width: 100%;
  overflow: hidden;
  height: calc(200vh - 1px);
  // height: 200vh;
  // //height: 1906px;

  // @media(max-width:1500px){
  //   height: 160vh;
  // }

 
  .typo {
    @media(max-width: 768px) {
      font-size: 25px;

    }
  }

  .largecircle {
    @media(max-width: 576px) {
      width: 750px !important; 
      height: 750px !important; 
      opacity: 1;
     
    }
  }

  .smallScreen {
    @media (max-width: 576px) {
      width: 400px !important; 
      height: 400px !important;     
    }

   
  }
  .gotoproject {
    text-decoration: none;
    color: white;
  }

  .circle {
   
    .small_circle {
      @media(max-width: 576px) {
        width: 270px;
        height: 270px;
        top: 65px;
        left: 65px;
      padding: 0;
        margin: 0; 
      }
    }
  }
}