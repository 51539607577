.right5 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    background-color: black;
    height: 300px;
    gap: 20px;
    margin-top: 100px;
  
    h3 {
      
      font-size: 20px;
      color: white;
  
      @media (max-width: 576px) {
        font-size: 15px;
      }
    }
  
    hr {
      width: 500px;
      margin: 0;
  
      @media (max-width: 576px) {
        width: 250px;
      }
    }
  
    .mail {
       
     // padding-right: 25px;
      width: 50px;
      height: 50px;
      color: white;
  
      @media (max-width: 576px) {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
  