.project1-wrapper {

    width: 100%;
    //  height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(max-width: 576px){
        padding-right: 0;
        margin-right: 0;
    }
    .first {
        background-color: white;
        width: 100%;
        height: 20px;
      


        .project1-header {

            font-family: 'Times New Roman', Times, serif;
            margin-top: 80px;
            position: fixed;
            height: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
            background: transparent;
            color: white;
            //  background-color: white;


            @media  (max-width: 576px) {

                margin-top: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 100px;
              position: static;
              
            }

            .navh1 {
                //margin-left: 170px;
                font-size: 35px;
                font-weight: normal;
                text-decoration: none;
                color: white;

                img {
                    width: 235px;
                    // height: 120px;
                    margin-left: 50px;
                    cursor: pointer;

                    @media(max-width: 576px){
                        width: 150px;
                    }

                }

                @media  (max-width: 576px){

                    font-size: 16px;
                    margin-left: -10px;
                }

            }


            // .navli1 {
            //     cursor: pointer;
            //     margin-right: -900px;
            //     list-style-type: none;
            //     font-size: 20px;
            //     text-decoration: none;
            //     color: white;

            //     @media (max-width: 500px) {

            //         font-size: 16px;
            //         margin-right: 50px;
            //     }

            // }

            .navli {
                cursor: pointer;
                text-decoration: none;
                font-size: 20px;
                list-style-type: none;
                color: black;

                &-lang {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 25px;
                    list-style-type: none;

                    @media (max-width: 520px) {
                        flex-direction: column-reverse;
                        gap: 10px;
                        padding-right: 0px;
                    }
                }

                @media (max-width: 576px) {

                    font-size: 16px;
                     
                }
       

            }


        }

        .off {
            position: fixed;
            color: black;
            text-decoration: none;
            font-size: 30px;
            margin-top: 100px;
            border: 2px solid black;
            cursor: pointer;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            transition: 1s ease-in-out;
            z-index: 999;
            font-weight: bold;
            left: 50%;

            
            @media (max-width: 576px) {

                font-size: 16px;
                width: 20px;
                height: 20px;
                text-align: center;
                margin-top: 50px;
            }

        }

        .off:hover {
            cursor: pointer;
            background-color: rgb(64, 63, 63);
            color: white;
            border: transparent;

        }

        .project-desc {
            text-align: center;
            margin-top: 210px;
            width: 100%;



            h1 {
                letter-spacing: 8px;
                font-size: 20px;
                text-align: center;
            }

            h4 {
                margin-left: 100px;
                letter-spacing: 8px;

                @media (max-width: 576px){
                    margin-top: 20px;
                }

            }

        }

    }


    .photos {

        background-color: #e3e0e0;
        margin-top: 330px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 70px;
        width: 100%;
        // margin-left: -1820px;
        padding-top: 130px;
        padding-bottom: 100px;
        @media(max-width:576px){
            width: 576px;
        }

@media(max-width:1000px){
    width: auto;
}


        img {
            width: 1000px;
            height: 700px;
            margin-top: 0px;
            padding: 0px;
            @media (max-width: 576px){
                width: 100%;
                height: 300px;
            }
        }
    }
}

.photo-number-bottom-right {
    position: fixed;
    bottom: 100px;
    right: 100px;
    display: flex;
    /* Use flexbox to align elements in one line /
    align-items: center;
    / Vertically center the elements */
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    z-index: 999;

    .line {
        width: 50px;
        @media (max-width: 800px){
            display: none;
        }
    }
    @media (max-width: 800px){
        display: none;
    }
}

.photo-number-bottom-right span {
    margin: 0 5px;
    /* Add spacing between the elements */
    @media (max-width: 800px){
        display: none;
    }
}

.photo-container {

    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    


    img {
        width: 100%;
        height: auto;
        object-fit: cover;

        @media(max-width:576px){
          //  width: 576px;
        }
    }



    .zoom-buttons {
        position: fixed;
        transform: translate(-50%, -50%);
        display: none;
        /* Initially hide the buttons */

        button {
            font-size: 2.0em;
            margin: 0 5px;
            cursor: pointer;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            border: transparent;
            background-color: black;
            color: grey;
            behavior: smooth;

            @media(max-width: 828px) {
                // height: 30px;
                // width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px;
                font-size: 1.7em;
               }
        }
    }

    &:hover .zoom-buttons {
        display: flex;


        /* Show the buttons on hover */
    }
}

// .photo-container.zoomed {
//     img {
//        // transform:scale(2);
//         width: 1600px;
//         height: 100vh;

//         /* You can adjust the zoom factor as needed /
//     }
// }
.photo-container.zoomed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    //Set a high z-index to make sure it's on top of other elements /
    background-color: black;
    // Set the background color if needed */
    transform: scale(1.0);
    behavior: smooth;
    
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media(max-width: 576px){
            object-fit: contain;
    width: 576px;
    height: 100%;
        }

        //object-fit: contain;
        /* Ensure the entire image is visible */
    }

    .zoom-buttons {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;

        @media(max-width: 828px) {
           width: 40px;
           height: 40px;
           }
        /* Show the buttons in zoomed mode */
    }
}

/* Add the following CSS to your Project1.scss file */


/* Add the following CSS to your Project1.scss file */

.next-text-overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    color: white;
    z-index: 1000;
    /* Black background with some transparency /
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    @media(max-width: 576px){
        width: 576px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    @media (min-width: 414px) and (max-width: 828px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
       }
    / Ensure the overlay is above other elements */
}

.next-text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 24px;
    font-family: 'Times New Roman', Times, serif;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    @media(max-width:576px){
        padding: 0;
    }
}

.next-text:hover {
    background-color: #333333;
    /* Darker background on hover */
}


.language3{
    list-style-type: none;
margin-top: 10px;
//margin-left:-800px;

@media(max-width:576px){
    margin-left: -100px;
}
    .lang3 {
    background-color: transparent;
    border: 1px solid #9c9a9a;
    border-radius: 5px;
    width: 45px;
    height: 45px;
    
    margin-left: 700px;
    
   // margin-top: 50px;
    cursor: pointer;

    list-style: none;
    
    @media(max-width: 576px){
        margin-right: -500px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
    }

}
}