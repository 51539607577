.all-wrapper {
    background-color: #252525;
    padding-bottom: 500px;
    width: 100%;

    @media (max-width: 576px) {
        padding-bottom: 200px;
        //   overflow: hidden;

    }


    .all-navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        width: 100%;
        //   height: -100vh;

        @media(max-width:576px) {
            height: 100px;
            z-index: 1;

        }

        .all-img {


            a {
                cursor: pointer;


                img {
                    width: 250px;
                    margin-left: 50px;
                    cursor: pointer;

                    @media (max-width: 576px) {
                        margin-left: -15px;

                        // margin-left: 130px;
                        // margin-top: 200px;
                        // display: none;
                    }

                }

            }
        }




        .all-links {

            // width: 45%;

            display: flex;
            align-items: center;
            list-style-type: none;

            @media (max-width: 520px) {
                flex-direction: column-reverse;
                gap: 10px;
                padding-right: 25px;
            }

            .li {
                padding-right: 35px;
                @media(max-width:830px){
                    padding-right: 55px;
                }
            }


            a {
                text-decoration: none;
                cursor: pointer;
                font-size: 20px;
                color: white;
                list-style-type: none;
                // padding-left: 100px;

                @media (max-width: 576px) {
                    padding-left: 0;
                    //width: 95px;
                    padding-right: 35px;

                }
            }
        }

    }

    .wrapper-circle {
        position: fixed;
        top: 100%;
        transform: translateY(-100%);
        width: 100%;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 576px) {
            height: 85vh;
            // margin-left: 130px;
            // margin-top: 200px;
            // display: none;
        }

    }

    .circle {
        border: 1px solid rgb(84, 83, 83);
        border-radius: 50%;
        width: 500px;
        height: 500px;


        //overflow: hidden;  
        // transition: 0.5s ease-out;

        @media (max-width: 576px) {
            width: 300px;
            height: 300px;
            // margin-left: 130px;
            // margin-top: 200px;
            // display: none;
        }
    }

    .all {

        @media (max-width: 576px) {
            display: block;
            // margin-left: 520px;
            position: relative;
            // height: 100vh;
        }

        .all-left {
            // position: absolute;
            padding-top: 200px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            margin-left: -480px;
            gap: 150px;

            @media (max-width: 576px) {
                // margin-left: 22px;
                //    display: flex;
                gap: 50px;
                align-items: flex-start;
                margin-left: 0px;
                //    padding-top: 160px;
                //    padding-bottom: 70px;
                padding-left: 0px;


            }
            @media(min-width: 850px) and (max-width:1024px){
                margin-left: -349px;
            }



            .all-box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 40px;

                @media (max-width: 576px) {
                    padding: 20px 20px 20px 90px;

                }

                @media(min-width:576px) and (max-width: 830px) {
                    padding: 20px 20px 20px 190px;
                }




                .image-container {
                    position: relative;

                    img {
                        width: 400px;
                        height: 250px;
                        object-fit: cover;
                        opacity: 0.3;
                        margin-left: -60px;
                        transition: opacity 0.5s ease-out;
                        cursor: pointer;

                        @media (max-width: 576px) {
                            width: 150px;
                            height: 90px;
                            // margin-right: 310px;

                        }

                        @media(min-width:576px) and (max-width: 830px) {
                            width: 300px;
                            height: 180px;
                        }

                    }

                    .hover-content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0;
                        transition: opacity 0.5s ease-out;
                    }

                    &:hover img {
                        opacity: 1;
                    }

                    &:hover .hover-content {
                        opacity: 1;
                    }

                    .all-text {
                        color: white;
                        letter-spacing: 8px;
                        // opacity: 100%;

                        p {
                            font-size: 15px;
                            color: grey;

                            @media (max-width: 576px) {
                                font-size: 10px;
                                margin-left: -10px;
                                //display: none;

                            }

                        }

                        h1 {
                            font-family: 'Times New Roman', Times, serif;
                            font-weight: normal;
                            margin-top: -10px;
                            font-size: 30px;

                            @media (max-width: 576px) {
                                font-size: 10px;
                                width: 200px;
                            }

                        }

                        h3 {
                            font-weight: normal;
                            margin-top: -10px;
                            font-size: 20px;

                            @media (max-width: 576px) {
                                font-size: 10px;
                                width: 200px;
                            }

                        }

                        @media (max-width: 768px) {
                            margin-left: -75px;
                            padding-top: 120px;
                            width: 200px;
                            display: flex;
                            flex-direction: column;
                            font-size: 15px;
                            gap: 10px;

                        }
                        @media(min-width:576px) and  (max-width: 830px){
                            margin-left: -95px;
                            padding-top: 30px;
                            width: 200px;
                            display: flex;
                            flex-direction: column;
                            font-size: 15px;
                            gap: 10px;

                        }
                        
                        

                        margin-top: -200px;
                        font-size: 20px;
                        margin-left: -150px;
                        width: 400px;
                        // transition: 0.5s ease-in-out;

                    }
                }


            }



        }


        .all-right {
            position: absolute;
            top: 400px;
            margin-left: calc(50% + 120px);
            flex-direction: column;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 150px;
            // @media (max-width: 876px) {
            //     margin-left: calc(50% + 16px);

            // }

            @media (max-width: 1024px) {
                margin-left: calc(50% + 89px);

            }
            // @media (max-width: 900px) {
            //     margin-left: calc(50%);

            // }

            @media (max-width: 576px) {
                top: 270px;
                gap: 26px;
                margin-left: calc(70%);

            }

            .all-box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 40px;

                @media (max-width: 576px) {
                    padding: 25px 0;


                }
                @media(max-width: 830px){
                    padding: 35px 0px;
                                    }
                    

                .image-container {
                    position: relative;

                    img {
                        width: 400px;
                        height: 250px;
                        object-fit: cover;
                        opacity: 0.3;
                        margin-left: -60px;
                        transition: opacity 0.5s ease-out;
                        cursor: pointer;

                        @media (max-width: 576px) {
                            width: 150px;
                            height: 90px;
                            //  margin-right: -550px;

                        }
                        @media(min-width:576px) and  (max-width: 830px){
width: 300px;
height: 180px;                                            }
                    }

                    .hover-content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0;
                        transition: opacity 0.5s ease-out;


                    }

                    &:hover img {
                        opacity: 1;
                    }

                    &:hover .hover-content {
                        opacity: 1;
                    }

                    .all-text {
                        letter-spacing: 8px;

                        //transition: transform 0.3s ease;
                        p {
                            font-size: 15px;
                            color: gray;

                            @media (max-width: 576px) {
                                font-size: 10px;
                                // display: none;
                            }
                        }

                        h1 {
                            font-family: 'Times New Roman', Times, serif;
                            font-weight: normal;
                            margin-top: -10px;
                            font-size: 30px;

                            @media (max-width: 576px) {
                                font-size: 10px;
                                width: 200px;
                            }
                        }

                        h3 {
                            font-weight: normal;
                            margin-top: -10px;
                            font-size: 20px;

                            @media (max-width: 576px) {
                                font-size: 10px;
                                width: 200px;
                            }

                        }


                        @media (max-width: 576px) {
                            padding-top: 120px;
                            width: 200px;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                        }
                        @media(min-width:576px) and  (max-width: 830px){
                            padding-top: 25px;
                            width: 300px;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            padding-left: 35px;

                                            }

                        color: white;
                        margin-top: -200px;
                        font-size: 20px;
                        margin-left: -130px;

                        @media(max-width:576px) {
                            margin-left: -95px;
                        }

                        display: flex;
                        flex-direction: column;

                        width: 300px;
                        //transition: 0.5s ease-in-out;

                    }
                }


            }


        }
    }


}