.about-wrapper {
    background-color: #252525;
    padding-top: 150px;
    width: 100%;
    margin: 0px;

    @media(max-width:576px){
        overflow: hidden;
        padding-top: 80px;
    }


    .about-first {
       // position: fixed;
        width: calc(100% - 175px);
        display: flex;
        justify-content: space-between;
        padding-left: 150px;
        padding-right: 25px;
        position: fixed;


        @media (max-width: 1300px) {

            width: calc(100% - 50px);
            padding-left: 25px;
        }

        @media (max-width: 830px) {
            position: relative;

            width: calc(100% - 50px);
            padding-left: 25px;
        }

        .language2 {
            // position: absolute;
            padding-right: 75px;
            list-style-type: none;
            color: white;
            font-size: 20px;
            text-decoration: none;
         
            @media(max-width:576px){
                // margin-left: 200px;
            
            //  overflow: hidden;
            }


            .lang2 {
                background-color: transparent;
                border: 1px solid #eee;
              //  border-radius: 50%;
                width: 45px;
                height: 45px;
               // margin-right: 200px;
                color: white;
                text-decoration: none;
                // margin-left: 1400px;
                // margin-top: 50px;
                cursor: pointer;
                list-style: none;

                @media(max-width:576px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0px;
                }


            }
        }

    }

    .off {
        //z-index: 1;
        color: white;
        text-decoration: none;
        font-size: 30px;
        border: 1px solid grey;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        transition: 1s ease-in-out;
        

        @media(max-width:576px) {
            margin-left: 20px;
            width: 20px;
            height: 20px;
        }

    }

    .off:hover {
        background-color: rgb(64, 63, 63);
        color: black;
        border: transparent;

    }

    .about-content-left {
        width: 30%;
        @media(max-width:576px){
           // overflow: hidden;
        }
      

    }

    .about-content-right {
        margin-left: 400px;
        margin-right: 0px;
        padding-right:0;
        margin-right: 0;
       

        @media (max-width: 830px) {
            margin-left: 25px;
        }

    }

    .about-left {
        display: flex;
        flex-direction: column;
        margin-top: 250px;
        margin-left: 150px;
        position: fixed;
        width: 250px;

        @media (max-width: 1300px) {
            margin-left: 50px;
        }
        @media (max-width: 830px) {
            position: relative;
            margin-top: 0;
            margin-left: 25px;
        }

        .left1 {
            display: flex;
            justify-content: center;
            flex-direction: column;
           

            h5 {
                color: white;
                font-size: 25px;

                @media (max-width: 576px) {
                    font-size: 15px;

                }
            }

            p {
                //text-align: center;
                color: grey;
                font-size: 20px;
                margin-top: -10px;

                @media (max-width: 576px) {
                    font-size: 15px;

                }
            }

            .hr {
                color: grey;
                width: 200px;
                margin-left: 1px;
                margin-top: -10px;

                @media (max-width: 576px) {
                    width: 95px;

                }
            }
        }

        .left2 {
            display: flex;
            justify-content: center;
            flex-direction: column;

            h5 {
                color: white;
                font-size: 25px;

                @media (max-width: 576px) {
                    font-size: 15px;

                }
            }

            p {
                color: grey;
                font-size: 20px;
                margin-top: -10px;

                @media (max-width: 576px) {
                    font-size: 15px;

                }
            }

            .hr {
                color: grey;
                width: 200px;
                margin-left: 1px;
                margin-top: -10px;

                @media (max-width: 576px) {
                    width: 150px;
                }
            }
        }
    }

    .about-right {
        display: flex;
        flex-direction: column;
        //     justify-content: flex-start;
        //  justify-content: flex-end;
        margin-top: 160px;
        // margin-left: 180px;
        width: 100%;
        padding-right:0;
        margin-right: 0;


        @media (max-width: 576px) {
            // margin-left: 70px;
            margin-top: 60px;
            width: 80%;
            padding-right: 25px;
            margin-right: 0;

            //margin-left: -260px;


        }

        .right1 {
            width: 100%;
            padding-right:0;
            margin-right: 0;
          

            @media(max-width:576px) {
                // display: flex;
                // justify-content: center;
                // align-items: center;
                //margin-left: -650px;
            }



            .nr {
                color: white;
                font-size: 20px;

                @media (max-width: 576px) {
                    font-size: 10px;
                }
            }

            h1 {
                color: white;
                font-size: 135px;
                width: 100%;

            //     @media(max-width: 400px) {
            //         font-size: 15px !important;
            //    }
                @media(max-width: 1300px) {
                     font-size: 50px !important;
                     margin-bottom: 30px;
                }
                @media(max-width: 500px){
                    font-size: 30px !important;
                }

                // @media screen and (max-width: 1576px) {
                //     font-size: 85px !important
                // }
                // @media (max-width: 1300px) {
                //     font-size: 40px !important;
                // }
                // @media (max-width: 1800px) {
                //     font-size: 95px;
                // }


            }

            p {
                line-height: 35px;
                color: grey;
                font-size: 20px;
                margin-top: -30px;
                width: 80%;

                @media (max-width: 576px) {
                    font-size: 10px;
                    line-height: 20px;
                    margin-top: 10px;
                    //   width: 600px;
                    //margin-left: 400px;
                    width: 100%;
                    //   color: red;
                }


            }
        }

       

    }

    .knowus {
        padding-right:0;
        margin-right: 0;
        h1 {
            color: white;
            font-size: 40px;
            margin-top: 30px;
            margin-bottom: 40px;
            width: 60%;

            @media(max-width:576px) {
                font-size: 25px;

            }
        }

        .video {
            padding-right:0;
            margin-right: 0;
            width: 80%;

            @media(max-width:576px) {
                width: 80%;
                //   margin-top: -150px;
                height: 150px;
            }


        }

    }

    .about-number{
        display: flex;
       
        align-items: center;
        gap: 60px;
        font-family: 'Times New Roman', Times, serif;
        margin-top: 50px;
        margin-bottom: 50px;

        @media(max-width:768px){
            display: block;
        }
        .box{
            h1{
                font-family: 'Times New Roman', Times, serif;

                font-size: 50px;
                color: white;

            }
            p{
                font-family: 'Times New Roman', Times, serif;
width: 250px;
                font-size: 20px;
                color: grey;
            }
           
        }
    }
    .about-portfolio{
        display: flex;
        align-items: center;
        
        .a{
            color: grey;
            text-decoration: none;
            font-size: 20px;
            margin-left: 10px;
        }
    }
    .about-location {
        margin-top: 50px;
       

        h1 {
            color: white;
            font-size: 40px;
            margin-bottom: 40px;
        

            @media(max-width:576px) {
                font-size: 25px;
                //   margin-left: -420px;
                margin-top: -50px;
            }


        }

        .location {
            width: 80%;

            @media(max-width:576px) {
                width: 80%;
                height: 350px;
                // margin-left: -150px;
            }



        }
    }

  
}