.navbar {
    font-family: 'Times New Roman', Times, serif;
    margin-top: 80px;
    position: fixed;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background: transparent;
    color: white;
    
    .links{
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 520px) {
            flex-direction: column-reverse;
            gap: 10px;
        }
    }

    .navh1 {
  
    

        img{
            width: 250px;
           // height: 120px;
           margin-left: 90px;
             @media (max-width: 768px) {

         width: 200px;
         margin-left: 10px;
         
          //  margin-left: -110px;
        }
        // @media only screen and (min-width: 414px) and (max-width: 828px) {
        //     /* Styles for iPhone 11 */
        //   width: 150px;
        // }
        }
       
        
    }


   

     
}
.lang {
    background-color: transparent;
   // border: 1px solid #eee;
    margin-left: 20px;
    cursor: pointer;

    @media(max-width:768px){
       // margin-left: -50px;
    }
}
.links{
    text-decoration: none;
    list-style: none;
    display: flex;
  
    .li {
        width: 100px; 

        @media(max-width: 768px){
            font-size: 20px;
        }
    .navli {
        cursor: pointer;
        text-decoration: none;
        font-size: 20px;
        list-style-type: none;
        color: white;
        margin-right: -1010px;

        @media (max-width: 768px) {

            font-size: 15px;
           // color: red;
            margin-top: -120px;
            margin-left:5px;
            
        }
        // @media (min-width: 414px) and (max-width: 828px) {
        //     /* Styles for iPhone 11 */
        // margin-left: -140px;
        // }
       
        
       

    }

}


}
   .language{
    @media(max-width:576px){
            margin-left: 20px;

    }
    
    .lang {
    background-color: transparent;
  //  border: 1px solid #eee;
    border-radius: 5px;
    margin-left: -150px;
    cursor: pointer;
    margin-top: -15px;
    font-family: 'Times New Roman', Times, serif;

    @media(max-width:768px){
        margin-left: -215px;
        margin-top: -5px;
    }
    // @media (min-width: 414px) and (max-width: 828px) {
    //     /* Styles for iPhone 11 */
    // margin-left: -330px;
    // margin-top: 10px;
    // }
   
}
}