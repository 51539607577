.error-page {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../Home//images//errorpage.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  color: white;
  font-size: 30px;
  .circle {
    border: 1px solid rgb(84, 83, 83);
    border-radius: 50%;
    //margin-top: 100px;
    //margin-left: 550px;
    width: 550px;
    height: 550px;
    position: fixed;
    transition: 0.5s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
      
    }
}

h2{
  font-size: 70px;
  margin-bottom: -30px;
}
h1{
  text-transform: uppercase;
}
  
  button {
    z-index: 1;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 10px;
    color: white;
    border-color:white;
    background-color: transparent;
    font-size: 15px;
    
  }
}